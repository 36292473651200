/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC, Fragment, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import ConfirmationModal from '../../../components/shared/modal/variants/ConfirmationModal';
import StatusTag, { StatusVariant } from '../../../components/shared/tags/StatusTag';
import { useToasts, ToastType } from '../../../contexts/ToastContext';
import User from '../../../models/User';
import AdminService from '../../../services/AdminService';
import UserService from '../../../services/UserService';
import { getHighestRole, Roles } from '../../../models/Role';
import Button, { ButtonSize, ButtonType } from '../../../components/shared/form-control/Button';
import { FormikState } from 'formik';
import { UserClientSpecificCustomData, UserProfile } from '../../../models/AuthModels';
import DropdownSelect from '../../../components/shared/form-control/DropdownSelect';
import { supportedLanguages } from '../../../types/Languages';
import ContextMenu from '../../../components/shared/ContextMenu';
import AuthService from '../../../services/AuthService';
import ClientService from '../../../services/ClientService';
import { ModalContext } from '../../../contexts/ModalContext';
import { Client } from '../../../models/Client';
import { useTopNavHeading } from '../../../global-state/Workspace';
import { TabStrip } from '../../../components/shared/tab-strip/TabStrip';
import { FieldRenderer } from '../../../components/user/FieldRenderer';
import { defaultMemberFields, MemberField, MemberFieldKey } from '../../../models/ClientMemberFields';
import ProfileAvatar, { ImageSize } from '../../../components/shared/profile-image/ProfileAvatar';
import useBusy from '../../../hooks/useBusy';
import DateUtils from '../../../utils/DateUtils';

const UserDetail: FC = () => {
  const { userId } = useParams<{ userId: string }>();
  const [user, setUser] = useState<User | null>(null);
  const { t } = useTranslation(['organisation', 'common', 'user-settings']);
  const [modal, setModal] = useState<JSX.Element | null>(null);
  const [hasArchitectRole, setHasArchitectRole] = useState(false);
  const toasts = useToasts();
  const resetFormRef = useRef<(nextState?: Partial<FormikState<UserProfile>> | undefined) => void | null>();
  const setTopNavheading = useTopNavHeading((x) => x.setValue);

  const [formState, setFormState] = useState<UserProfile>({} as UserProfile);
  const [errors, setErrors] = useState<Record<string, Record<string, string>>>({});
  const { isBusy, withBusy } = useBusy();
  const [isFormValid, setIsFormValid] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);

  const languageOptions = useMemo(() => {
    return supportedLanguages.map((lang) => ({
      id: lang.id,
      value: lang.value,
      text: t(`common:languages.${lang.id}`),
    }));
  }, [t]);
  const [changedRoles, setChangedRoles] = useState<Record<string, (typeof Roles)[keyof typeof Roles]>>({});
  const [accounts, setAccounts] = useState<Client[]>([]);

  useEffect(() => {
    AdminService.getAccounts({ pageNumber: 1, pageSize: 999 }).then((res) => {
      setAccounts(res.data);
    });
  }, []);

  const getUser = useCallback(() => {
    AdminService.getUser(userId as string).then((res) => {
      setUser(res.data);
      setHasArchitectRole(res.data.isArchitect);
    });
  }, [userId]);

  useEffect(() => {
    getUser();
  }, [getUser]);

  useEffect(() => {
    setFormState({
      email: user?.email || '',
      firstName: user?.firstName || '',
      lastName: user?.lastName || '',
      languageCode: user?.language || 'en',
      phoneNumber: user?.phoneNumber || '',
      address: user?.address || undefined,
      birthday: user?.birthday || undefined,
      userImageId: user?.userImageId || undefined,
      clientCustomData: user?.clientCustomData || {},
    });
  }, [user]);

  const validateField = useCallback(
    (key: string, value: any, isRequired: boolean): string | null => {
      if (isRequired && !value) {
        return t('common:validation.required');
      }
      return null;
    },
    [t],
  );

  useEffect(() => {
    const evalKeys = (obj: any, path: string[]): any => {
      return (
        path.reduce((acc, key) => {
          return acc?.[key];
        }, obj) || undefined
      );
    };

    const detectChanges = (obj: any, path: string[]): boolean => {
      const targetObj = evalKeys(obj, path);
      const sourceObj = evalKeys(user, path.slice(-1)[0] === 'languageCode' ? [...path.slice(0, -1), 'language'] : path);

      if (DateUtils.isValidDate(targetObj) || DateUtils.isValidDate(sourceObj)) {
        return targetObj !== sourceObj;
      }

      if (typeof targetObj === 'object') {
        return Object.keys(targetObj).some((key) => detectChanges(obj, [...path, key as string]));
      }

      return targetObj !== sourceObj;
    };

    const changesDetected = Object.keys(formState).some((key) => {
      return detectChanges(formState, [key]);
    });

    setHasChanges(changesDetected || Object.keys(changedRoles).length > 0);
  }, [changedRoles, formState, user]);

  const showArchitectModal = () => {
    setModal(
      <ModalContext.Provider value={{ open: true }}>
        <ConfirmationModal
          title={t(`common:permissions.user.modals.builder-access.${!hasArchitectRole ? 'grant' : 'revoke'}.title`)}
          description={t(`common:permissions.user.modals.builder-access.${!hasArchitectRole ? 'grant' : 'revoke'}.description`, {
            user: user?.firstName || t('common:permissions.user.modals.this-user'),
          })}
          confirmText={t(`common:permissions.user.modals.builder-access.${!hasArchitectRole ? 'grant' : 'revoke'}.title`)}
          onCancel={() => setModal(null)}
          onConfirm={async () => {
            if (user?.id)
              await UserService.updateArchitectPermission(user?.id, !hasArchitectRole)
                .then(() => {
                  setHasArchitectRole(!hasArchitectRole);
                })
                .catch((err) => {
                  if (err?.meta?.message)
                    toasts.addToast({
                      type: ToastType.ERROR,
                      title: 'Error',
                      description: err.meta.message,
                      expiresInMs: 2000,
                    });
                  console.error(err);
                })
                .finally(() => setModal(null));
          }}
        />
      </ModalContext.Provider>,
    );
  };

  const saveUser = useCallback(() => {
    if (!isFormValid || !user) return;

    withBusy(
      'save-profile',
      Promise.all([
        AdminService.updateUser(user.id, formState).then((res) => {
          setUser(res.data);
        }),
        ...Object.entries(changedRoles).map(([accountId, roleId]) => UserService.updateRole(user.id as string, accountId, roleId)),
      ] as Promise<any>[]).finally(() => {
        resetFormRef.current && resetFormRef.current();
        setChangedRoles({});
        getUser();
      }),
    );
  }, [isFormValid, user, withBusy, formState, changedRoles, getUser]);

  const safeAction = (newStatus: string, onConfirm: () => void) => {
    setModal(
      <ModalContext.Provider value={{ open: true }}>
        <ConfirmationModal
          title={t('common:permissions.user.modals.status.title')}
          description={
            <Trans
              t={t}
              i18nKey="common:permissions.user.modals.status.description"
              values={{
                user: user?.firstName || t('common:permissions.user.modals.this-user'),
                oldStatus: t(`common:user-status.${user?.active ? (user.verified ? 'active' : 'pending') : 'blocked'}`),
                newStatus: t(`common:user-status.${newStatus}` as any),
              }}
              components={{ bold: <strong /> }}
            />
          }
          confirmText={t('common:permissions.user.modals.status.title')}
          onCancel={() => setModal(null)}
          onConfirm={() => {
            setModal(null);
            onConfirm();
          }}
        />
      </ModalContext.Provider>,
    );
  };

  const confirm = useCallback(
    (modalKey: 'reset-password' | 'reset-2fa' | 'remove-user' | 'deactivate-user', onConfirm: () => void) => {
      setModal(
        <ModalContext.Provider value={{ open: true }}>
          <ConfirmationModal
            title={t(`common:permissions.user.modals.${modalKey}.title`)}
            description={t(`common:permissions.user.modals.${modalKey}.description`)}
            confirmText={t(`common:permissions.user.modals.${modalKey}.confirm-text`)}
            onCancel={() => setModal(null)}
            onConfirm={() => {
              setModal(null);
              onConfirm();
            }}
          />
        </ModalContext.Provider>,
      );
    },
    [t],
  );

  const deactivate = (userId: string) => {
    safeAction('deactivated', () =>
      UserService.disableUser(userId).then(() => {
        toasts.addToast({ title: t('common:permissions.toasts.disabled'), type: ToastType.SUCCESS, expiresInMs: 5000 });
        getUser();
      }),
    );
  };

  const activate = (userId: string) => {
    safeAction('enabled', () =>
      UserService.enableUser(userId).then(() => {
        toasts.addToast({ title: t('common:permissions.toasts.enabled'), type: ToastType.SUCCESS, expiresInMs: 5000 });
        getUser();
      }),
    );
  };

  const resendInvite = (userId: string, accountId: string) => {
    UserService.resendInvite(userId, accountId).then(() => {
      toasts.addToast({ title: t('common:permissions.toasts.resent-invite'), type: ToastType.SUCCESS, expiresInMs: 5000 });
    });
  };

  const resetPassword = () => {
    if (user?.email) {
      confirm('reset-password', () =>
        AuthService.forgotPassword(user.email as string).then(() => {
          toasts.addToast({ title: t('common:permissions.toasts.reset-password'), type: ToastType.SUCCESS, expiresInMs: 5000 });
        }),
      );
    }
  };

  const reset2Fa = (userId: string) => {
    confirm('reset-2fa', () =>
      UserService.reset2Fa(userId).then(() => {
        toasts.addToast({ title: t('common:permissions.toasts.reset-2fa'), type: ToastType.SUCCESS, expiresInMs: 5000 });
        getUser();
      }),
    );
  };

  const makeDefaultAccount = useCallback(
    (userId: string, accountId: string) => {
      AdminService.setDefaultAccount(userId, accountId).then((res) => {
        setUser(res.data);
        toasts.addToast({ title: t('common:permissions.toasts.default-set'), type: ToastType.SUCCESS, expiresInMs: 5000 });
      });
    },
    [t, toasts],
  );

  const removeFromAccount = useCallback(
    (userId: string, accountId: string) => {
      confirm('remove-user', () =>
        ClientService.removeUser(accountId, userId).then(() => {
          toasts.addToast({ title: t('common:permissions.toasts.removed-from-account'), type: ToastType.SUCCESS, expiresInMs: 5000 });
          getUser();
        }),
      );
    },
    [confirm, getUser, t, toasts],
  );

  const selectedUserName = user?.firstName || user?.lastName ? `${user?.firstName || ''} ${user?.lastName || ''}` : '-';

  useEffect(() => {
    setTopNavheading(selectedUserName);
    return () => {
      setTopNavheading('');
    };
  }, [selectedUserName, setTopNavheading]);

  const changeAccountRole = useCallback((accountId: string, roleId: (typeof Roles)[keyof typeof Roles]) => {
    setChangedRoles((prev) => ({ ...prev, [accountId]: roleId }));
  }, []);

  const userAccounts = useMemo(() => {
    return Object.entries(user?.roles ?? {})
      .map(([accountId, roles]) => ({ client: accounts.find((x) => x.id === accountId)!, roles }))
      .filter((x) => !!x.client);
  }, [accounts, user?.roles]);

  const handleFieldChange = (clientId: string, key: string, value: any, fieldInfo: MemberField | null) => {
    if (fieldInfo?.isUserField || key === 'userImageId') {
      setFormState((prev) => ({ ...prev, [key]: value }));
    } else {
      setFormState((prev) => ({
        ...prev,
        clientCustomData: {
          ...(prev.clientCustomData ?? {}),
          [clientId]: {
            ...(prev.clientCustomData?.[clientId] ?? {}),
            [key]: value,
          },
        },
      }));
    }

    setErrors((prev) => ({ ...prev, [clientId]: { ...(prev[clientId] ?? {}), [key]: '' } }));
  };

  const userFields = useMemo(() => {
    return defaultMemberFields.filter((x) => x.isUserField && x.key !== MemberFieldKey.Role);
  }, []);

  const accountFields = useMemo(() => {
    return defaultMemberFields.filter((x) => !x.isUserField || x.key === MemberFieldKey.Role).map((x) => x.key);
  }, []);

  const validateForm = useCallback(() => {
    const newErrors: Record<string, Record<string, string>> = {};
    let isValid = true;

    const validate = (fields: MemberField[], validationKey: string) => {
      for (const field of fields ?? []) {
        // Skip validation for non-editable fields
        if (!field.isEditable || [MemberFieldKey.Role, MemberFieldKey.BuilderAccess].includes(field.key)) {
          continue;
        }

        const error = validateField(
          field.key,
          field.key in formState
            ? formState[field.key as keyof UserProfile]
            : formState.clientCustomData?.[validationKey]?.[field.key as keyof UserClientSpecificCustomData],
          field.isRequired,
        );

        if (error) {
          newErrors[validationKey] ??= {};
          newErrors[validationKey][field.key] = error;
          isValid = false;
        }
      }
    };

    validate(userFields, 'user');
    for (const account of userAccounts) {
      validate(
        (account.client.memberFields?.length ? account.client.memberFields : defaultMemberFields).filter((x) => x.isRequired && x.isEditable),
        account.client.id,
      );
    }

    setErrors(newErrors);
    setIsFormValid(isValid);
  }, [formState, userAccounts, userFields, validateField]);

  useEffect(() => {
    validateForm();
  }, [validateForm]);

  const invitePermissionsOptions = useMemo(() => {
    return Object.values(Roles)
      .map((role) => ({ id: role, text: t(`common:roles.${role}` as any), value: role }))
      .sort((a, b) => a.text.localeCompare(b.text));
  }, [t]);

  return (
    <div className="bg-background-1 flex h-full flex-col overflow-auto">
      {modal}

      <div className="border-gray-2 sticky top-0 z-10 flex justify-between gap-3 border-b bg-white px-6 py-4">
        <div className="flex flex-grow items-center gap-4">
          {user && <ProfileAvatar size={ImageSize.L} user={{ ...user, userImageId: formState.userImageId }} />}
          <div>
            <div className="font-medium">
              {formState?.firstName || ''} {formState?.lastName || ''}
            </div>
            <div className="flex items-center gap-2">
              <span className="text-dpm-14">PW: </span>
              {user && (
                <div className="">
                  {user.isPasswordSet && (
                    <StatusTag data-cy={`user-password-set-${user.id}`} statusType={StatusVariant.GREEN} text={t('common:user-status.enabled')} />
                  )}
                  {!user.isPasswordSet && (
                    <StatusTag data-cy={`user-status-${user.id}`} statusType={StatusVariant.ORANGE} text={t('common:user-status.pending')} />
                  )}
                </div>
              )}
              <span className="text-dpm-14">2FA: </span>

              {user && (
                <div className="">
                  {user.isTwoFactorAuthenticationSet && (
                    <StatusTag data-cy={`user-password-set-${user.id}`} statusType={StatusVariant.GREEN} text={t('common:user-status.enabled')} />
                  )}
                  {!user.isTwoFactorAuthenticationSet && (
                    <StatusTag data-cy={`user-password-set-${user.id}`} statusType={StatusVariant.ORANGE} text={t('common:user-status.pending')} />
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="flex flex-shrink-0 items-center gap-2">
          <div className="flex items-start gap-4">
            <Button
              data-cy="save-user"
              title={t('user-settings:details.buttons.save')}
              type={ButtonType.PRIMARY}
              onClick={saveUser}
              disabled={!isFormValid || !hasChanges}
              size={ButtonSize.S}
              loading={isBusy('save-profile')}
            >
              {t('user-settings:details.buttons.save')}
            </Button>
          </div>

          <ContextMenu
            items={[
              {
                title: 'Reset Password',
                onClick: () => resetPassword(),
              },
              {
                title: 'Reset 2FA',
                onClick: () => reset2Fa(user!.id as string),
              },
              {
                title: t(`common:permissions.user.modals.builder-access.${!hasArchitectRole ? 'grant' : 'revoke'}.title`),
                onClick: () => showArchitectModal(),
              },
              {
                title: t('common:permissions.context-menu.disable'),
                onClick: () => deactivate(user!.id as string),
                hide: !user?.active && user?.verified,
              },
              {
                title: t('common:permissions.context-menu.enable'),
                onClick: () => activate(user!.id as string),
                hide: user?.active || !user?.verified,
              },
              {
                title: t('common:permissions.context-menu.resend-invite'),
                onClick: () => resendInvite(user!.id as string, user!.defaultAccountId as string),
                hide: !user?.active || user?.verified || !user.defaultAccountId,
              },
            ]}
          />
        </div>
      </div>
      <div className="border-gray-5 flex max-w-[650px] flex-col px-2 pb-8 pt-2">
        {userFields.map((field) => {
          const renderer = FieldRenderer[field.key as MemberFieldKey];
          if (!renderer) return null;

          return (
            <div key={field.key} className="px-4">
              {renderer({
                field: field,
                userProfile: formState,
                errors: errors['user'],
                onFieldChange: (key, value, _, field) => handleFieldChange('user', key, value, field),
                t,
                clientId: null,
              })}
            </div>
          );
        })}

        <div className="px-4">
          <DropdownSelect
            data-cy="pref-language"
            label={t('user-settings:details.prefered-language')}
            onChange={(data) => handleFieldChange('user', 'languageCode', data.value, null)}
            options={languageOptions}
            value={languageOptions.find((x) => x.value === formState.languageCode)}
          />
        </div>

        <TabStrip headerClassName="mt-6 bg-white rounded-md" allowWrapping>
          {user &&
            userAccounts.map(({ client, roles }) => {
              const accountRole: (typeof Roles)[keyof typeof Roles] = changedRoles[client.id] || getHighestRole(roles);

              const memberFields = (
                client.memberFields?.length ? client.memberFields : defaultMemberFields.filter((x) => x.isRequired && x.isEditable)
              ).filter((x) => accountFields.includes(x.key));

              return (
                <Fragment key={client.id}>
                  <TabStrip.TabHeader id={client.id} text={client.name}>
                    {client.id === user.defaultAccountId && (
                      <TabStrip.TabHeader.Slot name="Trailing">
                        <div className="-mr-5">
                          <StatusTag data-cy={`main-account-${client.id}`} statusType={StatusVariant.ORANGE} text="Main" />
                        </div>
                      </TabStrip.TabHeader.Slot>
                    )}
                  </TabStrip.TabHeader>

                  <TabStrip.TabContent forId={client.id}>
                    {client.id !== user.defaultAccountId && (
                      <div className="my-2 flex justify-end gap-2">
                        <Button onClick={() => removeFromAccount(user.id as string, client.id)} type={ButtonType.SECONDARY} size={ButtonSize.S}>
                          {t('common:permissions.context-menu.remove-from-account')}
                        </Button>

                        <Button onClick={() => makeDefaultAccount(user.id as string, client.id)} type={ButtonType.SECONDARY} size={ButtonSize.S}>
                          {t('common:permissions.context-menu.set-default-account')}
                        </Button>
                      </div>
                    )}

                    {memberFields
                      .filter((x) => accountFields.includes(x.key))
                      .map((field) => {
                        const renderer = FieldRenderer[field.key as MemberFieldKey];
                        if (!renderer) return null;

                        return (
                          <div key={field.key} className="px-4">
                            {renderer({
                              field: field,
                              userProfile: { ...formState, role: accountRole },
                              errors: errors[client.id],
                              onFieldChange: (key, value, _, field) =>
                                key === MemberFieldKey.Role ? changeAccountRole(client.id, value) : handleFieldChange(client.id, key, value, field),
                              t,
                              invitePermissionsOptions,
                              clientId: client.id,
                            })}
                          </div>
                        );
                      })}
                  </TabStrip.TabContent>
                </Fragment>
              );
            })}
        </TabStrip>
      </div>
    </div>
  );
};

export default UserDetail;
